.footer-container {
 display: flex;
 width: 350px;
 text-align: center;
 justify-content: center;
 margin-top: 10px;
 align-items: center;
}

.empty-footer{
    display: none;
}

.span-container {
    margin: 0 3px;
}

.spn-header {
    margin: 0 3px;
 padding: 3px;
 border-radius: 5px;
 background-color: var(--color3);
 color: black;
 font-size: 16px;
}

.spn {
 margin: 0 3px;
 padding: 3px;
 border-radius: 5px;
 background-color: var(--color3);
 color: black;
 font-size: 16px;
}

.spn:hover {
  
 background-color: transparent;
 color: white;
 cursor: pointer;
 box-shadow: 0px  3px var(--color3);
}

