:root{
  --color1 : #5A8F7B;
  --color2 :  #81CACF;
  --color3 :  #FFEA11;
}
*{
  margin: 0;
  padding: 0;
}
body {
  background-color: #355764;
  color: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
margin-top: 70px;
min-height: 300px;
  border-top: 1px solid var(--color2);
}
 
.app-header {
/* color: var(--color3); */
font-size: 100px;
font-family: Arial, Helvetica, sans-serif;
margin-bottom: 5px;
color: transparent;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: var(--color3);
}