.f-container{
    margin: 10px 0;
}

.input-area {
    padding: 5px;
    border: none;
    font-size: 18px;
    width: 350px;
    text-align: center;
    background-color: transparent;
    border: 1px solid var(--color3);
    color: var(--color3);
    border-radius: 10px;
}